@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

/* barlow-semi-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* parisienne-regular - latin-ext_latin */
@font-face {
	font-family: 'Parisienne';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ProximaNova-regular - latin-ext_latin */
@font-face {
	font-family: 'ProximaNova';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/ProximaNova-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/ProximaNova-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ProximaNova-regular - latin-ext_latin */
@font-face {
	font-family: 'ProximaNova';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/ProximaNova-Semibold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/ProximaNova-Semibold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ProximaNova-regular - latin-ext_latin */
@font-face {
	font-family: 'ProximaNova';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/ProximaNova-bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/ProximaNova-bold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ProximaNova-regular - latin-ext_latin */
@font-face {
	font-family: 'ProximaNovaExCn';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/ProximaNovaExCn-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/ProximaNovaExCn-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'ProximaNovaExCn';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/ProximaNovaExCn-Thin.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/ProximaNovaExCn-Thin.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/*Bitter-fonts*/
@font-face {
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/Bitter-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Bitter-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/Bitter-Bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Bitter-Bold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/*Jost-fonts*/
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/Jost-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Jost-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/Jost-Bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Jost-Bold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/Jost-Medium.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Jost-Medium.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/*QwitcherGrypen-fonts*/
@font-face {
	font-family: 'QwitcherGrypen';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/QwitcherGrypen-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/QwitcherGrypen-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'QwitcherGrypen';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/QwitcherGrypen-Bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/QwitcherGrypen-Bold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}